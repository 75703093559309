import { createI18n } from 'vue-i18n'
import elementEnLocale from 'element-plus/lib/locale/lang/en' // element-ui lang
import elementZhLocale from 'element-plus/lib/locale/lang/zh-cn' // element-ui lang
import zhLocale from './zh'
import enLocale from './en'
import { isEmpty } from '@/utils/utils'

const messages = {
	zh: {
		...zhLocale,
		...elementZhLocale,
	},
	en: {
		...enLocale,
		...elementEnLocale,
	},
}

export function getLanguage() {
	let language = null
	if (isEmpty(localStorage.getItem('language'))) {
		language = 'zh'
		localStorage.setItem('language', language)
	} else {
		language = localStorage.getItem('language')
	}
	return language
}

const i18n = createI18n({
	locale: getLanguage(),
	messages,
})

export default i18n
