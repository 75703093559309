import router from '@/router';
import {
    setToken,
    getToken,
    removeToken,
    setUserInfo,
    removeUserInfo,
    getUserInfo,
    getBasicUserInfo
} from '@/utils/userToken';
import {getLogoutPath} from "@/utils/utils";

const LOGIN_REDIRECT = "LOGIN_REDIRECT";
const BASIC_USER_INFO = "BASIC_USER_INFO";
export default ({
    namespaced: true,
    state: () => ({
        userToken: getToken(),
        // userInfo.userType 登录类型 1 公司 2 学生 3 学校 4 个人
        userInfo: getUserInfo(),
        basicUserInfo: getBasicUserInfo(),
        isLogin: false,
        loginRedirect: null,
        refreshHome: false
    }),
    actions: {
        SET_USER_TOKEN: ({commit}, token) => {
            if (token && token.length > 0) {
                commit('SET_USER_TOKEN', {token});
                //router.push(router.app._route.query.redirect || '/');
            }
        },
        SET_USER_INFO: ({commit}, user) => {
            commit('SET_USER_INFO', user);
        },
        logout: ({commit, state}) => {
            const userType = state.userInfo.userType
            const path = getLogoutPath(userType)
            const currentPath = router.currentRoute.value.fullPath
            commit('REMOVE_USER_TOKEN')
            commit('REMOVE_USER_INFO')
            commit('SET_LOGIN_REDIRECT',currentPath);
            router.replace(path)
        }
    },
    mutations: {
        SET_USER_TOKEN: (state, {token}) => {
            state.userToken = token
            setToken(token)
        },
        REMOVE_USER_TOKEN(state) {
            state.userToken = '';
            removeToken();
        },
        SET_USER_INFO: (state, user) => {
            state.userInfo = user;
            setUserInfo(user);
        },
        REMOVE_USER_INFO(state) {
            state.userInfo = null;
            removeUserInfo();
        },
        SET_LOGIN(state, isLogin) {
            state.isLogin = isLogin
        },
        SET_LOGIN_REDIRECT(state, loginRedirect) {
            state.loginRedirect = loginRedirect;
        },
        SET_BASIC_USER_INFO(state, basicUserInfo) {
            state.basicUserInfo = basicUserInfo;
            sessionStorage.setItem(BASIC_USER_INFO, JSON.stringify(basicUserInfo));
        }
    }
})
