<template>
  <GlobalHeader></GlobalHeader>
  <router-view />
  <GlobalFooter></GlobalFooter>
</template>
<script>
import GlobalHeader from "@/components/GlobalHeader.vue";
import { SET_IS_PC } from '@/store/constant'
import GlobalFooter from "@/components/GlobalFooter";
export default {
  name: 'HomeView',
  components: {
    GlobalHeader,
    GlobalFooter
  },
  mounted() {
    this.judge()
    window.addEventListener('resize', () => {
      this.judge()
    })
  },
  methods: {
    judge() {
      const isPC = document.documentElement.clientWidth >= 768
      this.$store.commit(`system/${SET_IS_PC}`, isPC)
    }
  }

}
</script>
<style lang="scss"></style>
