import user from './modules/user';
import certificate from './modules/certificate';
import student from './modules/student';
import school from './modules/school';
import system from './modules/system';
import news from './modules/news';

export default {
  user,
  certificate,
  student,
  school,
  system,
  news
}
