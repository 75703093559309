const student = [{
    path: '/schoolLogin',
    name: 'schoolLogin',
    component: () => import(/* webpackChunkName: "schoolLogin" */ '@/views/school/SchoolLogin.vue'),
    meta: {title: '学校登录', menuIndex: 3},
}, {
    path: '/schoolHome',
    name: 'schoolHome',
    component: () => import(/* webpackChunkName: "school" */ '@/views/school/SchoolHome.vue'),
    meta: {title: '学校服务', menuIndex: 3},
},
    {
        path: '/accountManagement',
        name: 'accountManagement',
        component: () => import(/* webpackChunkName: "accountManagement" */ '@/views/school/AccountManagement.vue'),
        meta: {title: '学校服务', menuIndex: 3},
    },
    {
        path: '/schoolExamList',
        name: 'schoolExamList',
        component: () => import(/* webpackChunkName: "schoolExamList" */ '@/views/school/SchoolExamList.vue'),
        meta: {title: '学校服务', menuIndex: 3},
    },
    {
        path: '/addExam',
        name: 'addExam',
        component: () => import(/* webpackChunkName: "addExam" */ '@/views/school/AddExam.vue'),
        meta: {title: '学校服务', menuIndex: 3},
    },
    {
        path: '/editExam',
        name: 'editExam',
        component: () => import(/* webpackChunkName: "addExam" */ '@/views/school/EditExam.vue'),
        meta: {title: '学校服务', menuIndex: 3},
    },
    {
        path: '/schoolExamDetails',
        name: 'schoolExamDetails',
        component: () => import(/* webpackChunkName: "schoolExamDetails" */ '@/views/school/SchoolExamDetails.vue'),
        meta: {title: '学校服务', menuIndex: 3},
    },
    // StudentList
    {
        path: '/studentList',
        name: 'studentList',
        component: () => import(/* webpackChunkName: "schoolExamDetails" */ '@/views/school/StudentList.vue'),
        meta: {title: '学校服务', menuIndex: 3},
    },
    {
        path: '/addStudentInfo',
        name: 'addStudentInfo',
        component: () =>
            import(/* webpackChunkName: "addStudentInfo" */ '@/views/school/AddStudentInfo.vue'),
        meta: {title: '学校服务',menuIndex:3},
    },
    {
        path: '/editStudentInfo',
        name: 'editStudentInfo',
        component: () =>
            import(/* webpackChunkName: "editStudentInfo" */ '@/views/school/EditStudentInfo.vue'),
        meta: {title: '学校服务',menuIndex:3},
    },
]

export default student