import {SET_CURRENT_MENU, SET_IS_PC} from '../constant'
export default {
	namespaced: true,
	state: () => ({
		isPC: false,
		currentMenu:-1
	}),
	actions: {},
	mutations: {
		[SET_IS_PC](state, payload) {
			state.isPC = payload
		},
		[SET_CURRENT_MENU](state, payload){
			state.currentMenu = payload
		}
	},
}
