import {SET_PERMS} from '../constant'
import {PERMS} from "@/utils/permission";
export default {
    namespaced: true,
    state: () => ({
    
        //  1 学生管理、2 学生考试报名、3 申请参加考试 
        // 0 升级为主账号，4 上传考试成绩
        perms:localStorage.getItem(PERMS) ||'',
    }),
    actions: {},
    mutations: {
        [SET_PERMS](state, payload) {
            state.perms = payload
        },
    },
}
