import { GET, POST, POST_JSON } from '@/api/service'

export default {
	/**
	 * 登录
	 * @param data
	 * accountName String 是 手机号/邮箱
	 * password String 是 密码
	 * type int 是 登录类型 1 公司 2 学生 3 学校 4 个人
	 * @returns {*}
	 */
	login(data) {
		return POST_JSON(`/loginByPassword`, data)
	},
	/**
	 * 获取登录手机或邮箱验证码（发送验证码）
	 * @param data
	 * captchaCode: 验证码
	 * type: 1.邮箱 | 2.手机号
	 * emailOrMobileNO: 手机号或邮箱
	 * uuid: 图形验证码接口返回的uuid
	 * @returns {*}
	 */
	registerStudentSms(data) {
		return POST_JSON(`/anonymous/registerStudentSms`, data)
	},
	// 获取邮箱验证码
	registerEmailCode(data) {
		return POST_JSON(`/anonymous/registerEmailCode`, data)
	},
	/**
	 * 获取图形验证码
	 * @returns {*}
	 */
	getPicVerificationCode() {
		return GET(`/anonymous/captchaImage`)
	},
	// 个人注册
	registerIndividual(data) {
		return POST_JSON(`/anonymous/registerIndividual`,data)
	},

	//公司注册
	registerCompany(data) {
		return POST_JSON(`/anonymous/registerCompany`,data)
	},

	// 在线重置密码
	resetPwd(data) {
		return POST_JSON(`/web/user/resetPwd`,data)
	},
	// 验证 手机号 验证码 返回加密手机号用于重置密码
	/**
	 * @param data
	 * phonenumber String 是 手机号
	 * code String 是 图形验证码
	 * uuid String 是 图形验证码对应的uuid
	 * phonenumberCode String 是 手机验证码
	 * @returns {*}
	 */
	vailRestPhonenumberCode(data) {
		return POST_JSON(`/anonymous/vailRestPhonenumberCode`,data)
	},

	/**
	 * 根据加密手机号code 重置密码
	 * @param data
	 * password String 是 密码
	 * encoderCode String 是 手机号加密code
	 * @returns {*}
	 */
	restPassword(data) {
		return POST_JSON(`/anonymous/restPassword`,data)
	},
	// 验证 邮箱 验证码 返回加密邮箱用于重置密码
	/**
	 * @param data
	 * email String 是 邮箱
	 * code String 是 图形验证码
	 * uuid String 是 图形验证码对应的uuid
	 * emailCode String 是 邮箱验证码
	 * @returns {*}
	 */
	vailResetEmailCode(data) {
		return POST_JSON(`/anonymous/vailResetEmailCode`,data)
	},
	// 根据加密邮箱code 重置密码
	/**
	 * password String 是 密码
	 * encoderCode String 是 手机号加密cod
	 * @param data
	 * @returns {*}
	 */
	resetPasswordByEmail(data) {
		return POST_JSON(`/anonymous/resetPasswordByEmail`,data)
	},
}
