<template>
  <div class="home">
    <div class="banner-wrapper">
      <div class="inner-wrapper">
        <el-carousel :height="carouselHeight" autoplay class="banner-list">
          <el-carousel-item class="banner-item" v-for="item in bannerList" :key="item">
            <a :href="item.linkUrl ? item.linkUrl : '#'"><img  :src="item.imgUrl"
                class="banner-img cp" alt="banner-img"></a>
          </el-carousel-item>
        </el-carousel>
        <div class="menu-list">
          <div class="menu-item cp" v-for="item in menuList" @click="onMenuClick(item)" :key="item">
            <img :src="item.icon" class="icon-menu" />
            <span>{{ item.label }}</span>
          </div>
        </div>
      </div>

    </div>
    <div class="news-wrapper">
      <div class="title">资讯动态</div>
      <div class="split-line"></div>
      <!-- <div class="view-more cp">查看全部消息</div> -->
      <div class="news-list">
        <div class="news-item" v-for="item in newsList" :key="item">
          <div class="news-title cp" @click="toDetail(item)">· {{ item.title }}</div>
          <div class="date">{{ item.newsTime }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, reactive, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from "vue-router";
import { SET_CURRENT_MENU } from "@/store/constant";
import api from '@/api'
import dayjs from "dayjs";

const store = useStore()
const router = useRouter()
const bannerList = ref([])
const menuList = ref([
  { label: "考点入口", value: 1, path: '/schoolHome', icon: require('@/assets/icon/exam.png') },
  { label: "AHK职业教育培训介绍", value: 2, path: '', icon: require('@/assets/icon/intro.png') },
  { label: "操作指南", value: 3, path: '/', icon: require('@/assets/icon/touch.png') },
])
const isPC = computed(() => {
  return store.state.system.isPC
})
const more = computed(() => {
  return '查看全部消息'
})
const toDetail = (item) => {
  // store.commit(`system/${SET_CURRENT_MENU}`,1)
  router.push({
    path: '/newsDetails',
    query: { id: item.id }
  })
}
const toHotNews = (item) => {
  store.commit(`system/${SET_CURRENT_MENU}`, 1)
  router.push({
    path: '/hotNews',
    query: {}
  })
}
const onMenuClick = (item) => {
  switch (item.value) {
    case 1:
      if (!store.state.user.userToken) {
        router.push('/schoolLogin')
        return
      }
      router.push({
        path: item.path
      })
      break;
    case 2:
      window.open('https://china.ahk.de/cn/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/%E8%81%8C%E4%B8%9A%E6%95%99%E8%82%B2%E5%9F%B9%E8%AE%AD', '_blank')
      break;
    case 3:
      // todo 完善操作指南链接
      break;
  }
}

const examList = ref([])
const firstNews = ref({})
const newsList = ref([])
const getNewsList = async () => {
  const { code, msg, rows } = await api.news.getNewsList({
    pageNum: 1,
    pageSize: 6,
    type: 1 // 考试
  })
  if (code === 200) {
    firstNews.value = formatDate(rows?.slice(0, 1))[0]
    examList.value = formatDate(rows?.slice(1, 4))
    newsList.value = formatDate(rows)
  } else {
    window.$message({ message: msg })
  }
}
const formatDate = (list) => {
  return list.map(item => {
    const { newsTime } = item
    return {
      ...item,
      newsTime: dayjs(item.newsTime).format('YYYY-MM-DD'),
    }
  })
}
const getBannerList = async () => {
  const { code, msg, data } = await api.news.getBannerList()
  if (code === 200) {
    bannerList.value = data
    handleCarouselHeight(isPC.value)
  } else {
    window.$message({ message: msg })
  }
}
function handleCarouselHeight(isPC) {
  if (isPC) {
    carouselHeight.value = '470px'
  } else {
    const img = new Image();
    img.src = bannerList.value[0]?.imgUrl;
    img.onload = () => {
      const deviceWidth = window.innerWidth;
      carouselHeight.value = (deviceWidth * img.height / img.width).toFixed(2) + 'px'
    };
  }

}
watch(isPC, (newValue) => {
  handleCarouselHeight(newValue)
})
const carouselHeight = ref('0')
onMounted(() => {
  carouselHeight.value = isPC.value ? '470px' : '235px'
  getNewsList()
  getBannerList()
})
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
:deep(.el-carousel__indicator--horizontal .el-carousel__button)  {
    width: 10px;
    height: 10px;
    background: rgba(255, 255, 255, 1);   
     border-radius: 50%;
  }  
  :deep(.el-carousel__indicator--horizontal.is-active .el-carousel__button) {
    background: #003882;
  }

.banner-wrapper {
  font-size: 48px;
  line-height: 55px;
  z-index: 1;
  padding: 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: $color-banner-title;
  margin-top: 100px;

  .inner-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .banner-list {
    width: 100%;

    .banner-item {
      width: 100%;

      .banner-img {
        width: 100%;
      }
    }
  }

  .menu-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 14px;

    .menu-item {
      width: 100%;
      height: 60px;
      border-radius: 10px;
      background: #003881;
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: bold;
      line-height: 29px;
      gap: 25px;
      color: #FFFFFF;
      padding: 0 31px;

      .icon-menu {
        width: 20px;
      }
    }
  }
}

.news-wrapper {
  width: 100%;
  margin: 24px auto 53px;

  .title {
    font-size: 20px;
    font-weight: bold;
    line-height: normal;

    /* AHK DARK BLUE */
    color: #003882;
    text-align: center;
  }

  .split-line {
    width: 100%;
    height: 2px;
    background: #003882;
    margin: 12px 0;
  }

  .news-list {
    display: flex;
  }

  .view-more {
    text-align: right;
    font-size: 16px;
    color: #3D3D3D;
    margin-bottom: 23px;
    padding: 0 14px;
  }

  .news-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 8px;

    .news-item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      padding: 0 14px;

      .news-title {
        color: #1A1A1A;
        width: 70%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
          text-decoration: underline;
          text-decoration-color: #003881;
        }

      }

      .date {
        color: #979797;
      }
    }
  }
}

.line {
  width: 100%;
  height: 8px;
  background: #003882;
  margin-top: 100px;
}


.btn-wrapper {
  margin-top: 39px;
  margin-bottom: 39px;
}

/* pc */
@media only screen and (min-width: 768px) {
  .banner-wrapper {
    // height: 518px;
    color: $color-banner-title;
    display: flex;
    justify-content: center;
    margin-top: 0px;
    padding: 25px 0 35px;
    background: linear-gradient(0deg, #E5EBF2 0%, rgba(229, 235, 242, 0) 100%);

    .inner-wrapper {
      width: $wrapper-width;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
    }

    .banner-list {
      width: 737px;
      height: 470px;

      .banner-item {
        width: 100%;

        // height: 470px;
        .banner-img {
          width: 100%;
        }
      }
    }

    .menu-list {
      display: flex;
      flex-direction: column;
      gap: 53px;
      padding: 0;

      .menu-item {
        width: 420px;
        height: 120px;
        border-radius: 20px;
        opacity: 1;
        background: #003881;
        display: flex;
        align-items: center;
        font-size: 28px;
        font-weight: bold;
        line-height: 29px;
        gap: 25px;
        color: #FFFFFF;
        padding: 0 31px;

        .icon-menu {
          width: 48px;
        }
      }
    }

  }

  .news-wrapper {
    width: $wrapper-width;
    margin: 24px auto 53px;

    .title {
      font-size: 32px;
      font-weight: bold;
      line-height: normal;
      letter-spacing: 0em;

      /* AHK DARK BLUE */
      color: #003882;
      text-align: center;
    }

    .split-line {
      width: 100%;
      height: 3px;
      background: #003882;
      margin: 12px 0;
    }

    .news-list {
      display: flex;
    }

    .view-more {
      text-align: right;
      font-size: 16px;
      color: #3D3D3D;
      margin-bottom: 23px;
      padding: 0;
    }

    .news-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 8px;

      .news-item {
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 20px;
        padding: 0;

        .news-title {
          color: #1A1A1A;
          width: 380px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &:hover {
            text-decoration: underline;
            text-decoration-color: #003881;
          }

        }

        .date {
          color: #979797;
        }
      }
    }
  }


}
</style>