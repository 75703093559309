import axios from 'axios'
import store from '@/store'
import router from "@/router";
import {getLogoutPath} from "@/utils/utils";
const openApi = axios.create({
	baseURL: process.env.VUE_APP_API_BASE_URL,
	timeout: 20000,
	headers: {
		'Content-Type': 'application/json',
	},
})

openApi.interceptors.request.use(
	(config) => {
		if (store.state?.user?.userToken && config.headers) {
			config.headers.Authorization = `Bearer ${store.state.user.userToken}`
		}
		return config
	},
	(error) => {
		return Promise.reject(error)
	}
)

// response interceptor

let isRefreshing = false

openApi.interceptors.response.use(
	(response) => {
		if (response.data.code === 401) {
			if(isRefreshing) return
			isRefreshing = true
			store.dispatch('user/logout')
		}else if (response.data.code === 200) {
			isRefreshing=false
		}
		return response.data
	},
	(error) => {
		const { response } = error
		if (!response) {
			window.$message({message: '系统正忙，请稍后重试！'});
		}
		return response ? response.data : Promise.reject(error)
	}
)

export { openApi }
