import { GET, POST, POST_JSON } from '@/api/service'
export  default {
    /**
     *
     * @param data
     * "type": 1  新闻类型 1考试、2新闻
     * @returns {*}
     */
    getNewsList(data) {
        let page = `pageNum=${data.pageNum}&pageSize=${data.pageSize}`
        return POST_JSON(`/web/news/getNewsList?${page}`, data)
    },
    // 获取新闻详情
    getNewsInfo({id}) {
        return POST_JSON(`/web/news/getNewsInfo/${id}`)
    },
    //获取banner
    getBannerList() {
        return POST_JSON('/anonymous/getBannerList')
    }
}