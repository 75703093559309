import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css/normalize.css'
import './styles/index.scss'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import i18n from "./lang/index";
import { ElMessage, ElLoading, ElMessageBox } from'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

const app = createApp(App);
app.config.globalProperties.$t = i18n.global.t;
window.$t = i18n.global.t;

const $ElMessage = options => {
    return ElMessage({
        ...options,
        offset: options.offset ? options.offset : 200,
        customClass: 'message-index'
    });
};
['success', 'warning', 'info', 'error'].forEach(type => {
    $ElMessage[type] = options => {
        if (typeof options === 'string') {
            options = {
                message: options
            };
        }
        options.offset = options.offset ? options.offset : 200;
        options.type = type;
        options.customClass = 'message-index';
        return ElMessage(options);
    };
});
window.$message = $ElMessage;
window.$messageBox = ElMessageBox;
// app.config.globalProperties.$loading = ElLoading;
app.config.globalProperties.$messageBox = ElMessageBox;
app.use(store);
app.use(router);
app.use(i18n);
app.use(ElementPlus, {locale: zhCn,});
app.mount('#app');