import { GET, POST, POST_JSON } from '@/api/service'

export default {
    /**
     * 学生获取个人信息
     * @param data
     * @returns {*}
     */
    getStudentInfo(data) {
        return POST_JSON(`/web/student/getStudentInfo`, data)
    },

    //已报名考试信息列表
    getSignupCourseList(data) {
        let page = `pageNum=${data.pageNum}&pageSize=${data.pageSize}`;
        return POST_JSON(`/web/student/getSignupCourseList?${page}`,data)
    },
    // 我的证书列表
    getCertificate() {
        return POST_JSON(`/web/student/getCertificate`)
    },
    // 核验身份证号
    checkIdnumber(data) {
        return POST_JSON(`/web/student/checkIdnumber`,data)
    },
    // 申诉更正身份证号发送 学校邮箱的接口
    /**
     *
     * @param data
     * "idnumber":"123456789012345678"
     * @returns {*}
     */
    sendAppealIdnumberEmail(data) {
        return POST_JSON(`/web/student/sendAppealIdnumberEmail`,data)
    },
    // 考试详情
    getSignupCourseInfo(data) {
        return POST_JSON(`/web/student/getSignupCourseInfo`,data)
    },

    // 新增学习资料
    addStudyInfo(data) {
        return POST_JSON(`/web/student/addStudyInfo `,data)
    },
    // editStudyInfo
    editStudyInfo(data) {
        return POST_JSON(`/web/student/editStudyInfo `,data)
    },
    // editStudyInfo
    delStudyInfo(id) {
        return POST_JSON(`/web/student/delStudyInfo/${id}`)
    },
    // getStudyInfoList
    getStudyInfoList(data) {
        let page = `pageNum=${data.pageNum}&pageSize=${data.pageSize}`;
        return POST_JSON(`/web/student/getStudyInfoList?${page}`,data)
    },
    uploadFile(data) {
        return POST_JSON(`/common/upload`, data, {
            'Content-Type': 'multipart/form-data',
        })
    },
    // 核验身份证状态
    // {idCardCheckStatus:  1核验 、2核验通过
    changeCardStatus(data) {
        return POST_JSON(`/web/student/changeCardStatus `,data)
    },
}
