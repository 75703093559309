export default {
	common: {
		LoadingText: 'Loading',
		cancelBtnText: 'Cancel',
		submitBtnText: 'Submit',
		sureBtnText: 'OK',
		sendBtnText: 'Send',
		sendSucceededMsg: 'Success',
		sendEmailMsg: 'Send to Email succeeded',
		unlimitedSelect: 'Does not matter',
		age: 'years old',
		emailOrPhone: 'Email/Telephone',
		emailOrPhonePlaceholder: 'Please input Email/Telephone',
		password: 'Password',
		passwordPlaceholder: 'Please set your password',
		passwordEmptyTip: 'Please input password',
		passwordFormatTips: "Password must contain both number and letter, password length between 8~16 characters.",
		forgetPassword: 'Forget password',
		confirmPassword: 'Confirm password',
		confirmPasswordTip: 'Please input your password again to confirm',
		confirmPasswordFormatTips: "Password must contain both number and letter, password length between 8~16 characters.",
		passwordDiffer: 'The two passwords you entered does not match',
		companyEmptyTip: 'Please input company name',
		companyLengthTip: 'Company name too short',
		companySelectEmptyTip:"Please select company",
		candidateBtnText: 'I am job seeker',
		businessBtnText: 'I am employer',
		login: 'Login',
		noAccount: 'No Account',
		candidateRegister: 'Job seeker register',
		businessRegister: 'Employer register',
		loginQrCodeText: 'Please Scan the QR code to experience in WeChat Mini Program.',
		personalCenter: 'Personal Center',
		logout: 'Logout',
		moreText: 'View more',
		contactUs: 'Contact us',
		messages: {
			success: 'succeeded',
			saveSuccess: "Saved",
			uploadSuccess: "Uploaded",
			deleteSuccess: "Deleted",
			submitSuccess: "Submitted"
		},
		messageBoxTitle: "Hint",
		confirmButtonText: 'Confirm',
		cancelButtonText: 'Cancel',
		gotItButtonText: 'OK',
		createButtonText: "Create",
		imgTip: 'Only support jpg、png format',
		videoTip: 'Only support mp4 format, Maximum Size 100M',
		selectProvinceAndCity: 'Select province and city(China only)',
		selectCountry: 'Select country',
		btnSaveText: 'Save',
		email: 'Email',
		emailPlaceholder: 'Please input Email',
		emailEmptyTip: "Please input Email",
		emailErrorTip: 'Email address not valid',
		phone: 'Telephone',
		phonePlaceholder: 'Please input telephone',
		phoneErrorTip: 'Telephone not vaild（Only support telephone number in mainland China）',
		imgVerificationTip: 'Please input the verification code',
		imgCodeEmptyTip: 'Verification code is empty',
		imgCodeSuccess: 'Verification code Sent',
		messageCodeTitle: 'Verification code',
		messageCode: 'Send code',
		messageErrorCode: 'Please input verification code',
		emailPhoneEmptyTip: "Please input Email/telephone",
		incorrectEmailPhoneFormat: "Email or telephone not valid",
		collection: 'Collection',
		download: 'Download',
		downloadToEmail: 'Download to Email',
		nationwide:"China",
		all:"All",
		languageSelect:{
			Chinese:"Chinese",
			German:"German",
			English:"English",
			others:"Others",
			unlimited:"Does not matter"
		},
		loginTip:"Please login first",
		uploadingText: "Uploading",
		submittingText: "Submitting",
		emptyText: "No data",
		name:"姓名",
		namePlaceholder:"请输入姓名",
		idNumber: '身份证号',
		idNumberTip:"请输入身份证号",
		idNumberErrorTip:"请输入正确的身份证号",
		page:{
			home:"首页",
			examIntroduction:"考试介绍",
			hotNews:"最新消息",
			AhkIntroduction:'AHK介绍',
			schoolHome:'考点入口',
			studentHome:"考生入口",
			verify:"证书验证",
			personalInfo:"个人信息",
			learningLog:"学习日志",
			accountManagement:"账号管理",
			examList:"考试列表",
			addExam:"添加考试",
			applyExam:"申请考试",
			studentList:'学生列表',
			addStudentInfo:"添加学生",
			editStudentInfo:"编辑学生信息"
		},
		editPassword:"修改密码",
		editSuccess:"修改成功",
		menuTip1:"请在考生服务--我的证书查看证书",
		menuTip2:"请使用学生账号登录查看",
		menuTip3:"请使用证书验证账号登录查看",
		menuTip4:"请使用学校账号登录查看",
		resetPassword:"重置密码",
		picCodeTip:'点击图片刷新',
		studentLogin:'考生登录',
		schoolLogin: '考点登录',
		operation:"操作"
	},
	register:{
		pageTitle:"证书验证-账号注册",
		enterpriseRegister:"企业账号注册",
		PersonalAccount:"个人账号注册",
		hasAccount:"已有账号",
		ruleTip: '请勾选用户协议',
	},
	personalInfo:{
		school:"就读学校",
		studentNumber:"学号",
		year:"入学年份",
		verifyBtnText:"核验身份证号码",
		modify:"修改",
		tip:"提示信息:请核验身份证号码",
		tip2:"提示信息:身份证号码核验不匹配申诉中",
		conformity:"身份证号码一致",
		inconformity:"输入身份证号码与注册号码不一致",
		successfulAppeal:"申诉已提交",
		appealToIdnumber:'是否需要申诉更正身份证号？'
	},
	myCertificate:{
		pageTitle:"我的证书",
		tip:"提示信息xxx",
		certificateNumber:"证书编号",
		scanCopy:"查看电子扫描件"
	},
	examList:{
		pageTitle:"我的考试",
		date:"考试时间",
		notStarted:"尚未开始",
		passed:"已通过",
		exam:"考试",
		status:{
			notApplied:"未申请",
			applied:"已申请",
			approved:"审核通过",
			fail:"审核不通过",
			finished:"已完成考试",
			cancel: '取消'
		},
	},
	LearningLog:{
		deleteConfirmText:'确认删除此条日志?',
		onlineExamScore:"理论考试成绩",
		offlineExamScore:"实操考试成绩",
		name:"考试名称",
		detail:"考试详情",
		date:"考试时间",
		status: '考试状态',
		examScore:"考试成绩",
		addLog:"添加学习日志"
	},
	studentHome:{
		personalInfo:"个人信息",
		myExam:"我的考试",
		myCertificate:"我的证书",
		examLog:"学习日志",
		candidateServices:"考生服务"
	},
	schoolHome:{
		schoolServices:'考点服务',
		accountManagement:"账号管理",
		examList:"考试列表",
		studentList:'学生列表'
	},
	accountManagement:{
		schoolInfo:"考点信息",
		schoolName:"考点名称",
		address:"考点地址",
		contactName:"考点联系人",
		contactPhone:"考点电话",
		remark:"备注",
		accountName:"账号名",
		mainAccount:"主账号",
		subAccountManagement:"子账号管理",
		tip:'包括本校基本信息和子账号',
		enable:"启用",
		disable:"停用",
		accountInfo:"账号信息"
	},
	schoolExamList:{
		status:{
			notApplied:"未申请",
			applied:"已申请",
			approved:"审核通过",
			fail:"审核不通过",
		},
		applyStatus:"申请状态",
		details:"详情",
		emptyText: '当前暂无进行中的考试，如要申请考试，请点击右上方“+申请考试”'
	},
	addExam:{
		applyDesc:"申请描述",
		applyData:"申请资料",
		offlineStartTime:"实操考试开始时间",
		offlineEndTime:"实操考试结束时间"
	},
	addStudentInfo:{
		enrollYearTip:"请填写入学时间",
		specialty:"请选择专业",
		schoolStudentIdTip:"请填写学号"
	},
	schoolExamDetail:{
		status:{
			notApplied:"未申请",
			applied:"已申请",
			approved:"审核通过",
			fail:"审核不通过",
			finished:"已完成考试",
			cancel: '取消'
		}
	}
}
