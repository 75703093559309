<template>
  <div class="header">
    <header :class="{ sticky: !isPC }">
      <div v-if="isPC" class="logo-wrapper">
        <img @click="goHome" class="logo cp" src="@/assets/logoNew.png" alt="logo">
        <div class="split-line"></div>
        <img class="logo-text" alt="" src="@/assets/logo-text.png">
      </div>
      <img  @click="goHome" v-else class="logo-mobile" src="@/assets/logo-mobile.png" alt="logo">
      <div class="tab-wrapper">
        <div class="language f-align f-dn">
          <img class="arrow" src="@/assets/icon/arrow-down.png" alt="logo">
          {{ language }}
          <img v-if="isPC" class="flag" src="@/assets/icon/ZH_CN.png" alt="logo">
        </div>
        <template v-if="isPC">
          <div class="text link" @click="toContactus">{{ contact }}</div>
          <div class="text link" @click="toAbout">{{ about }}</div>
          <span class="text link" v-if="userInfo" @click="logout">{{ logoutText }}</span>
        </template>
      </div>
      <div class="unknown f-center" v-if="isPC">
        <div class="circle f-center">
          #
        </div>
        <div class="unknown-text">{{ unknownText }}</div>
      </div>
      <div class="menu" @click="open" v-if="!isPC">
        <img class="icon-menu" src="@/assets/icon/icon-menu.png" alt="logo">
      </div>
    </header>
    <div class="line" v-if="isPC"></div>

    <!-- <div class="menu f-center" :class="{ sticky: showFixedMenu }" v-if="isPC">
      <img v-if="showFixedMenu" class="logo-mobile" src="@/assets/logo-mobile.png" alt="logo">
      <ol class="list-wrapper">
        <li class="menu-item" @click="changeMenu(index,menu)" :class="{ active: menu.value === currentMenu }"
          v-for="(menu, index) in menuList" :key="menu.value">
          <span>{{ menu.label }}</span>
        </li>
      </ol>
    </div> -->
    <template v-if="showMenu && !isPC">
      <div class="mask"></div>
      <div class="menu-popup">
        <div class="menu-container">
          <div class="top">
            <span class="title">Menu</span>
            <img @click="close" class="icon-close" src="@/assets/icon/close.png">
          </div>
          <div class="center">
            <ol class="list-wrapper">
              <li class="menu-item f-align" @click="changeMenu(index, menu)" v-for="(menu, index) in menuList"
                :key="menu.value">
                <span>{{ menu.label }}</span>
              </li>
            </ol>
          </div>
          <div class="bottom">
            <div class="line"></div>
            <span class="text" @click="toContactus">{{ contact }}</span>
            <span class="text" @click="toAbout">{{ about }}</span>
            <span class="text" v-if="userInfo" @click="logout">{{ logoutText }}</span>
            <div class="unknown f-center">
              <div class="circle f-center">
                #
              </div>
              <div class="unknown-text">{{ unknownText }}</div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
// eslint-disable-next-line no-unused-vars
import {ref, computed, reactive, onMounted, watch, getCurrentInstance} from 'vue'
import { useStore } from 'vuex'
import {useRouter,useRoute} from "vue-router";
import {SET_CURRENT_MENU} from "@/store/constant";
import {useI18n} from "vue-i18n";

const {t} = useI18n()
const {$message} = getCurrentInstance().appContext.config.globalProperties
const route=useRoute()
const router=useRouter()
const allRoutes=router.getRoutes()
const store = useStore()
const language = computed(() => {
  return 'ZH_CN'
})
const contact = computed(() => {
  return '联系我们'
})
const about = computed(() => {
  return '关于我们'
})
const logoutText = computed(() => {
  return t('common.logout')
})
const unknownText = computed(() => {
  return 'PartnerForGrowth'
})
const isPC = computed(() => {
  return store.state.system.isPC
})
const menuList = computed(() => {
  return [
    { label: "考点入口", value: 3, path: '/schoolHome' },
    { label: "AHK职业教育培训", value: 2 ,path:'/'},
    { label: "操作指南", value: 0 ,path:'/'},
    // { label: "最新消息", value: 1 ,path:'/hotNews'},
    // { label: "考生入口", value: 4 ,path:'/studentHome'},
    // { label: "证书验证", value: 5 ,path:'/verify'}
  ]
})
const currentMenu = computed(()=>{
  return store.state.system.currentMenu
})
const userInfo=computed(()=>{
  return  store.state.user.userInfo
})
const userType=computed(()=>{
  return   userInfo.value?.userType
})
const showMenu = ref(false)
const showFixedMenu = ref(false)
const changeMenu = (index, menu) => {
  close()
  if ([3,4, 5].includes(menu.value)) {
    if (!store.state.user.userToken) {
      if (menu.value === 5)  toLogin()
      if (menu.value === 4) toStudentLogin()
      if (menu.value === 3) toSchoolLogin()

    }else {
      if(menu.value === 5){
        if(userType.value === 2){
          $message({message: t('common.menuTip1')})
          return
        }
        if(userType.value===3){
          $message({message: t('common.menuTip3')})
          return;
        }
      }
      if (userType.value !== 2 && menu.value === 4) {
        $message({message: t('common.menuTip2')})
        return
      }
      if (userType.value !== 3 && menu.value === 3) {
        $message({message: t('common.menuTip4')})
        return
      }

      router.push(menu.path)
    }
  } else if (menu.value === 2) {
    window.open('https://china.ahk.de/cn/%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE/%E8%81%8C%E4%B8%9A%E6%95%99%E8%82%B2%E5%9F%B9%E8%AE%AD', '_blank')
    return
  } else {
    router.push(menu.path)
  }
  store.commit(`system/${SET_CURRENT_MENU}`, menu.value)
}
function open() {
  showMenu.value = true
}
function close() {
  showMenu.value = false
}
const goHome=()=>{
  store.commit(`system/${SET_CURRENT_MENU}`,0)
  router.push('/')
}
const toLogin=()=>{
  router.push('/login')
}
const toStudentLogin=()=>{
  router.push('/studentLogin')
}
const toSchoolLogin=()=>{
  router.push('/schoolLogin')
}
const logout=()=>{
    store.dispatch('user/logout')
    close()
}
const toContactus=()=>{
  router.push('/contactus')
}
const toAbout=()=>{
  router.push('/about')
}
watch(() => route.path,(newPath)=>{
  let currentMenu=-1
   const targetRoute = allRoutes.find(item=>{
      return  item.path===newPath
    })
  if(targetRoute){
    currentMenu=targetRoute.meta.menuIndex
    store.commit(`system/${SET_CURRENT_MENU}`,currentMenu)
  }

},{
  immediate:true
})
onMounted(() => {
  //使用window.addEventListener监听滚动条
  window.addEventListener('scroll', () => {
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    if (isPC.value) {
      showFixedMenu.value = scrollTop > 130
    }
  })
})

</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

.f-dn {
  display: none;
}
.header {
  width: 100%;
  position: fixed;
  height: 100px;
  z-index: 99;
  top: 0;
  left:0;
  background: #FFFFFF;
}
.line{
  width: 100%;
  height: 8px;
  background: #003882;
}

.unknown {
  width: 204px;
  height: 37px;
  opacity: 1;
  background: #003882;
  color: #FFFFFF;
  display: flex;

  .circle {
    width: 22px;
    height: 22px;
    background: #99afcd;
    border-radius: 50%;
    font-weight: 900;
    line-height: 17px;
    margin-right: 11.5px;

    .unknown-text {
      font-weight: bold;
    }
  }
}

header {
  display: flex;
  padding: 27px 22px;
  align-items: center;
  position: relative;
  width: 100%;

  &.sticky {
    position: fixed;
    top: 0;
    z-index: 10;
  }

  .logo-mobile {
    width: 96px;
    margin-right: 20px;
  }

  .tab-wrapper {
    display: flex;
    flex-direction: row;
    //font-size: 0.683rem;
    font-size: 14px;
    line-height: 17px;
    color: #474747;

    .language {
      cursor: pointer;
    }

    .language {
      font-weight: 600;

      .arrow {
        margin-right: 4px;
      }
    }
  }

  .menu {
    position: absolute;
    right: 22px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.mask {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000000;
  opacity: 0.3;
  z-index: 98;
}

.menu-popup {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
  display: flex;
  justify-content: flex-end;

  .menu-container {
    width: 335px;
    height: 100%;
    z-index: 99;
    background: $popBackground;
    font-size: 12px;


    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 63px;
      padding: 0 23px 0 22px;

      .title {
        color: var(--mm-color-text);
        opacity: 0.7;
        font-size: 12px;
      }
    }

    .center {
      padding: 0 23px 0 22px;

      .list-wrapper {
        display: flex;
        flex-direction: column;

        .menu-item {
          height: 44px;
          color: var(--mm-color-text);
          font-size: 18px;
        }
      }
    }

    .bottom {
      padding: 42px 23px 0 22px;
      display: flex;
      flex-direction: column;
      gap: 22px;
      color: var(--mm-color-text);
      font-size: 14px;
      position: relative;

      .line {
        width: 100%;
        height: 1px;
        background: #CAD5DA;
        position: absolute;
        top: 0;
        left: 0px;
      }

      .text {
        opacity: 0.7;
      }

      .unknown {
        width: 183px;
        margin-top: 46px;

      }
    }

  }
}

@media screen and (min-width: 768px) {
  .header {
    position: relative;
    height: auto;
    margin: 0 auto;
     display: flex;
     flex-direction: column;
     align-items: center;
    //  margin: auto 90px;
    header {
      display: flex;
      padding: 29px 90px 21px 90px;
      align-items: flex-end;
      justify-content: space-between;
      // width: $wrapper-width;
      
      .logo-wrapper{
        display: flex;
        gap: 19px;
        align-items: center;
      }
      .logo {
        width: 294px;
      }
      .split-line{
        height: 68px;
        width: 1px;
        background:#D5D5D5 ;
      }
      .logo-text{
        width: 124px;
      }
      .tab-wrapper {
        display: flex;
        flex-direction: row;
        font-size: 0.683rem;
        font-weight: normal;
        line-height: 17px;
        color: #474747;

        .language,
        .text {
          cursor: pointer;
          margin-right: 25px;
        }

        .text:last-child {
          margin-right: 0px;
        }

        .language {
          font-weight: 600;

          .arrow {
            margin-right: 4px;
          }

          .flag {
            width: 17px;
            margin-left: 4px;
          }
        }
      }

      .unknown {
        width: 204px;
        height: 37px;
        position: absolute;
        top: 0;
        right: 0px;
      }
    }

    .menu {
      height: 60px;
      background: #f3f3f3;
      .logo-mobile {
        margin-right: 40px;
        margin-left: 90px;
      }

      .list-wrapper {
        display: flex;
        gap: 40px;

        .menu-item {
          font-size: 20px;
          font-weight: 250;
          //width: 130px;
          display: flex;
          justify-content: center;
          position: relative;
          cursor: pointer;

          &.active::after {
            content: "";
            width: 100%;
            height: 4px;
            background: #003882;
            position: absolute;
            bottom: -16px;
          }
        }
      }

      &.sticky {
        width: 100%;
        position: fixed;
        justify-content: flex-start;
        top: 0;
        height: 75px;

        .menu-item.active::after {
          bottom: -24px;
        }
      }
    }

  }
}</style>