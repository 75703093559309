import { GET, POST, POST_JSON } from '@/api/service'

export default {
  /**
   * 基于证书编号和个人身份证验证
   * @param data
   * certificationId string 是 证书编号
   * idnumber string 是 身份证号
   * code String 是 图形验证码
   * uuid String 是 图形验证码对应的uuid
   * @returns {*}
   */
  checkCertificationId(data) {
    return POST_JSON(`/web/certificate/checkCertificationId`, data)
  },
  /**
   * 基于姓名+身份证号验证
   * @param data
   * @returns {*}
   */
  checkName(data) {
    return POST_JSON(`/web/certificate/checkName`, data)
  },
  /**
   * 获取图形验证码--证书专用
   * @returns {*}
   */
  getQrCaptchaImage() {
    return GET(`/getQrCaptchaImage`)
  },
  // 获取证书信息
  qrCodeCheckCert(data) {
    return POST_JSON(`/web/certificate/qrCodeCheckCert`, data)
  },
}
