<template>
  <div class="footer">
    <div><span class="link" @click="toPrivacyPolicy">隐私政策</span>｜<span class="link"
        @click="toStandardTerms">标准条款</span>｜<span class="link" @click="toDisclaimer">免责声明</span></div>
    <div>© 2023 德中工商技术咨询服务（太仓）有限公司 | German Industry & Commerce Greater China</div>
    <div class="wrapper">
      <img class="icon" src="@/assets/icon/police.png">
      <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32058502010764"
        class="icp">苏公网安备
        32058502010764号
      </a> <span>|</span>
      <a href="http://beian.miit.gov.cn/" target="_blank" class="icp">苏ICP备2023025789号-1</a>
    </div>
    <div>
      <span>违法不良信息举报电话/关爱未成年举报电话:025-84412377</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "GlobalFooter",
  methods:{
    toPrivacyPolicy(){
      this.$router.push('/privacyPolicy')
    },
    toStandardTerms(){
      this.$router.push('/standardTerms')
    },
    toDisclaimer(){
      this.$router.push('/disclaimer')
    }
  }
}
</script>

<style scoped lang="scss">
.footer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 30px 22px;
  align-items: center;
  font-size: 8px;
  font-weight: 600;
  line-height: 27px;
  text-align: center;
  border-top: 1px solid #CAD5DA;
  background: #282B2D;
  color: #F5F5F5;
  .icp {
    color: #F5F5F5;
    text-decoration:none;
  }
  .wrapper{
      display: flex;
    align-items: center;
    gap: 4px;
    .icon{
      width: 15px;
      height: 15px;
    }
  }
}
.link{
  cursor: pointer;
  color: #B0D2FF
}
@media only screen and (min-width: 768px) {
  .footer {
    font-size: 14px;
    font-weight: 600;
  }
}
</style>