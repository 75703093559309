export default {
	common: {
		LoadingText: '正在努力加载',
		cancelBtnText: '取消',
		submitBtnText: '提交',
		sureBtnText: '确定',
		sendBtnText: '发送',
		sendSucceededMsg: '发送成功',
		sendEmailMsg: '已发送至邮箱！',
		unlimitedSelect: '不限',
		age: '岁',
		emailOrPhone: '邮箱/手机号',
		emailOrPhonePlaceholder: '请输入邮箱/手机号',
		password: '密码',
		passwordPlaceholder: '请设置密码',
		passwordEmptyTip: '请输入密码',
		passwordFormatTips: "密码必须包含字母大小写，且在8~20位之间",
		forgetPassword: '忘记密码',
		confirmPassword: '确认密码',
		confirmPasswordTip: '请输入确认密码',
		confirmPasswordFormatTips: "密码必须包含字母大小写，且在8~20位之间",
		passwordDiffer: '两次输入的密码不一致',
		companyEmptyTip: '请输入企业名称',
		companyLengthTip: '企业名称长度过短',
		companySelectEmptyTip:"请选择企业",
		candidateBtnText: '我是求职者',
		businessBtnText: '我是企业',
		login: '登录',
		noAccount: '没有账号',
		candidateRegister: '求职者注册',
		businessRegister: '企业注册',
		loginQrCodeText: '欢迎扫描二维码在小程序内体验。',
		personalCenter: '个人中心',
		logout: '退出登录',
		moreText: '查看更多',
		contactUs: '联系我们',
		messages: {
			success: '操作成功',
			saveSuccess: "保存成功",
			uploadSuccess: "上传成功",
			deleteSuccess: "删除成功",
			submitSuccess: "提交成功"
		},
		messageBoxTitle: "提示",
		confirmButtonText: '确认',
		cancelButtonText: '取消',
		gotItButtonText: '知道了',
		createButtonText: "去完善",
		imgTip: '仅支持jpg、png',
		videoTip: '仅支持mp4，视频大小不超过100M',
		selectProvinceAndCity: '选择省市',
		selectCountry: '选择国家',
		btnSaveText: '保存',
		email: '邮箱',
		emailPlaceholder: '请输入邮箱',
		emailEmptyTip: "请输入邮箱",
		emailErrorTip: '邮箱格式不正确',
		phone: '手机号',
		phonePlaceholder: '请输入手机号',
		phoneErrorTip: '手机号格式不正确',
		imgVerificationTip: '请输入右侧验证码',
		imgCodeEmptyTip: '图形验证码不能为空',
		imgCodeSuccess: '验证码发送成功！',
		messageCodeTitle: '验证码',
		messageCode: '获取验证码',
		messageErrorCode: '请输入验证码',
		emailPhoneEmptyTip: "请输入邮箱或手机号",
		incorrectEmailPhoneFormat: "邮箱或手机格式不正确",
		collection: '收藏',
		download: '下载',
		downloadToEmail: '下载至邮箱',
		nationwide:"全国",
		all:"全部",
		languageSelect:{
			Chinese:"中文",
			German:"德语",
			English:"英语",
			others:"其他",
			unlimited:"不限"
		},
		loginTip:"请先登录",
		uploadingText: "正在上传",
		submittingText: "正在提交",
		emptyText: "暂无数据",
		name:"姓名",
		namePlaceholder:"请输入姓名",
		idNumber: '身份证号',
		idNumberTip:"请输入身份证号",
		idNumberErrorTip:"请输入正确的身份证号",
		page:{
			home:"首页",
			examIntroduction:"考试介绍",
			hotNews:"最新消息",
			AhkIntroduction:'AHK介绍',
			schoolHome:'考点入口',
			studentHome:"考生入口",
			verify:"证书验证",
			personalInfo:"个人信息",
			learningLog:"学习日志",
			accountManagement:"账号管理",
			examList:"考试列表",
			addExam:"添加考试",
			applyExam:"申请考试",
			studentList:'学生列表',
			addStudentInfo:"添加学生",
			editStudentInfo:"编辑学生信息"
		},
		editPassword:"修改密码",
		editSuccess:"修改成功",
		menuTip1:"请在考生服务--我的证书查看证书",
		menuTip2:"请使用学生账号登录查看",
		menuTip3:"请使用证书验证账号登录查看",
		menuTip4:"请使用学校账号登录查看",
		resetPassword:"重置密码",
		picCodeTip:'点击图片刷新',
		studentLogin:'考生登录',
		schoolLogin: '考点登录',
		operation:"操作"
	},
	register:{
		pageTitle:"证书验证-账号注册",
		enterpriseRegister:"企业账号注册",
		PersonalAccount:"个人账号注册",
		hasAccount:"已有账号",
		ruleTip: '请勾选用户协议',
	},
	personalInfo:{
		school:"就读学校",
		studentNumber:"学号",
		year:"入学年份",
		verifyBtnText:"核验身份证号码",
		modify:"修改",
		tip:"提示信息:请核验身份证号码",
		tip2:"提示信息:身份证号码核验不匹配申诉中",
		conformity:"身份证号码一致",
		inconformity:"输入身份证号码与注册号码不一致",
		successfulAppeal:"申诉已提交",
		appealToIdnumber:'是否需要申诉更正身份证号？'
	},
	myCertificate:{
		pageTitle:"我的证书",
		tip:"提示信息xxx",
		certificateNumber:"证书编号",
		scanCopy:"查看电子扫描件"
	},
	examList:{
		pageTitle:"我的考试",
		date:"考试时间",
		notStarted:"尚未开始",
		passed:"已通过",
		exam:"考试",
		status:{
			notApplied:"未申请",
			applied:"已申请",
			approved:"审核通过",
			fail:"审核不通过",
			finished:"已完成考试",
			cancel: '取消'
		},
	},
	LearningLog:{
		deleteConfirmText:'确认删除此条日志?',
		onlineExamScore:"理论考试成绩",
		offlineExamScore:"实操考试成绩",
		name:"考试名称",
		detail:"考试详情",
		date:"考试时间",
		status: '考试状态',
		examScore:"考试成绩",
		addLog:"添加学习日志"
	},
	studentHome:{
		personalInfo:"个人信息",
		myExam:"我的考试",
		myCertificate:"我的证书",
		examLog:"学习日志",
		candidateServices:"考生服务"
	},
	schoolHome:{
		schoolServices:'考点服务',
		accountManagement:"账号管理",
		examList:"考试列表",
		studentList:'学生列表'
	},
	accountManagement:{
		schoolInfo:"考点信息",
		schoolName:"考点名称",
		address:"考点地址",
		contactName:"考点联系人",
		contactPhone:"考点电话",
		remark:"备注",
		accountName:"账号名",
		mainAccount:"主账号",
		subAccountManagement:"子账号管理",
		tip:'包括本校基本信息和子账号',
		enable:"启用",
		disable:"停用",
		accountInfo:"账号信息"
	},
	schoolExamList:{
		status:{
			notApplied:"未申请",
			applied:"已申请",
			approved:"审核通过",
			fail:"审核不通过",
		},
		applyStatus:"申请状态",
		details:"详情",
		emptyText: '当前暂无进行中的考试，如要申请考试，请点击右上方“+申请考试”'
	},
	addExam:{
		applyDesc:"申请描述",
		applyData:"申请资料",
		offlineStartTime:"实操考试开始时间",
		offlineEndTime:"实操考试结束时间"
	},
	addStudentInfo:{
		enrollYearTip:"请填写入学时间",
		specialty:"请选择专业",
		schoolStudentIdTip:"请填写学号"
	},
	schoolExamDetail:{
		status:{
			notApplied:"未申请",
			applied:"已申请",
			approved:"审核通过",
			fail:"审核不通过",
			finished:"已完成考试",
			cancel: '取消'
		}
	}
}
