import { createStore } from 'vuex'
import system from './module/system'
import user from './module/user'
import school from './module/school'
export default createStore({
	state: {},
	getters: {},
	mutations: {},
	actions: {},
	modules: {
		system: system,
		user,
		school
	},
})
