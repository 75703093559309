import {  POST_JSON ,downloadBlob} from '@/api/service'
export default {

    /**
     * 获取学校基本信息
     * @param data
     * @returns {*}
     */
    getSchoolInfo(data) {
        return POST_JSON(`/web/school/getSchoolInfo`, data)
    },
    // 修改学校信息
    updateSchool(data) {
        return POST_JSON(`/web/school/updateSchool`, data)
    },

    /** 获该学校 学生列表
     * @param data
     * schoolStudentId string 是 学号
     * name String 是 名称
     * contactPhone String 是 手机号
     * enrollYear string 否 入学年份
     * @returns {*}
     */
    getLoginStudentList(data) {
        let page = `pageNum=${data.pageNum}&pageSize=${data.pageSize}`;
        return POST_JSON(`/web/school/getLoginStudentList?${page}`, data)
    },
    /**
     * 获取学生基本信息
     * id long 是 学生id
     */
    getStudentInfo(data) {
        return POST_JSON(`/web/school/getStudentInfo`, data)
    },
    /**
     * 编辑学生详情
     * id long 是 学生id
     */
    editStudent(data) {
        return POST_JSON(`/web/school/editStudent`, data)
    },
    /**
     * 关闭/打开学生账号账号
     * id long 是 学生id
     * closeFlag bool 是 是否关闭账号
     */
    closeStudentUser(data) {
        return POST_JSON(`/web/school/closeStudentUser`, data)
    },
    /**
     * 添加学生 并发送 邮箱激活
     * schoolStudentId string 是 学号
     * name string 是 名称
     * idnumber string 是 身份证号
     * contactPhone string 是 手机号
     * contactEmail string 是 邮箱
     * specialty string 是 专业
     * enrollYear string 是 入学年份
     * password string 是 密码
     */
    insertStudent(data) {
        return POST_JSON(`/web/school/insertStudent`, data)
    },
    /**
     * 学校 批量 学生报名 审核通过
     * studl list 是 学生id集合
     * courseId long 是 考试id
     */
    batchSchoolSignup(data) {
        return POST_JSON(`/web/school/batchSchoolSignup`, data)
    },
    /** 查询考试列表
     * @param data
     * pageNum int 是 页码
     * pageSize int 是 页数
     * @returns {*}
     */
    getCourseList(data) {
        let page = `pageNum=${data.pageNum}&pageSize=${data.pageSize}`;
        return POST_JSON(`/web/school/getCourseList?${page}`, data)
    },
    /**
     * 学校参加考试

     */
    joinCourse(data) {
        return POST_JSON(`/web/school/joinCourse`, data)
    },
    /**
     * 学校参加考试
     * pageNum int 是 页码
     * pageSize int 是 页数
     * courseId long 是 考试id
     */
    getStudentCourseList(data) {
        let page = `pageNum=${data.pageNum}&pageSize=${data.pageSize}`;
        return POST_JSON(`/web/school/getStudentCourseList?${page}`, {
            courseId:data.courseId
        })
    },
    getCourseSchoolInfo(data) {
        return POST_JSON(`/web/school/getCourseSchoolInfo`, {
            courseId:data.courseId
        })
    },
    // 学校查看参加的单个考试详情
    getJoinCourseInfo(data) {
        return POST_JSON(`/web/school/getJoinCourseInfo`, {
            courseId:data.courseId
        })
    },
    // 学校修改参加的考试
    updateJoinCourseInfo(data) {
        return POST_JSON(`/web/school/updateJoinCourseInfo`, data)
    },
    // 获取主账号以及子账号
    getSchoolUserInfoList() {
        return POST_JSON(`/web/school/getSchoolUserInfoList`)
    },
    // 修改主账号邮箱
    editSchoolUserEmail(data) {
        return POST_JSON(`/web/school/editSchoolUserEmail`,data)
    },
    // 设置子账号权限
    editSchoolPerms(data) {
        return POST_JSON(`/web/school/editSchoolPerms`,data)
    },
    // 创建子账号 addChildSchoolUser
    addChildSchoolUser(data) {
        return POST_JSON(`/web/school/addChildSchoolUser`,data)
    },

    // 获取未申请的考试列表
    getNotApplyCourseList() {
        return POST_JSON(`/web/school/getNotApplyCourseList`)
    },

    // 学生取消报名
    cancelSignup(data) {
        return POST_JSON(`/web/school/cancelSignup`,data)
    },
     // 获取
    getAllSpecialty(data) {
        return POST_JSON(`/web/specialty/getAllSpecialty`,data)
    },
    // 启用停用 子账号
    editSchoolUserStatus(data) {
        return POST_JSON(`/web/school/editSchoolUserStatus`,data)
    },
    exportTemp() {
        return downloadBlob(`/web/school/exportTemp`,)
    },
    batchImportStudentData(data) {
        return POST_JSON(`/web/school/batchImportStudentData`, data, {
            'Content-Type': 'multipart/form-data',
        })
    },
    studentGetCourseList(data){
        let page = `pageNum=${data.pageNum}&pageSize=${data.pageSize}`
        return POST_JSON(`web/school/studentGetCourseList?${page}`,data)
    },
    // 获取考试模板文件
    getCourseTempFile(){
        return POST_JSON(`/web/courseTempFile/getCourseTempFile`)
    },
    // 导出学生考试名单
    exportStudentData() {
        return downloadBlob(`/web/school/exportStudentData`)
    },
    // 获取学校的专业列表
    getSchoolExamSpecialty(data) {
        return POST_JSON(`/web/school/getSchoolExamSpecialty`, data)
    },
    //批量更新学生的毕业状态
    batchUpdateGraduationStatus(data) {
        return POST_JSON(`/web/school/batchUpdateGraduationStatus`, data)
    },
    // 学校主账号更新手机号和名称
    editSchoolUserPhone(data) {
        return POST_JSON(`/web/school/editSchoolUserPhone`,data)
    }
}
