export const TOKEN_KEY = 'WEB_AUTH_TOKEN';
export const USER_INFO = 'WEB_USER_INFO';
const BASIC_USER_INFO = "BASIC_USER_INFO";
export const setToken = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
}

export const getToken = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (token) {
    return token
  } else {
    localStorage.setItem(TOKEN_KEY, "");
  }
  return ''
}

export const removeToken = () => {
  const token = localStorage.getItem(TOKEN_KEY)
  if (token) localStorage.removeItem(TOKEN_KEY)
}

export const setUserInfo = (userInfo) => {
  localStorage.setItem(USER_INFO, JSON.stringify(userInfo));
}

export const getUserInfo = () => {
  const userInfo = localStorage.getItem(USER_INFO);
  if (userInfo) {
    return JSON.parse(userInfo)
  } else {
    localStorage.setItem(USER_INFO, "");
  }
  return ''
}

export const removeUserInfo = () => {
  const userInfo = localStorage.getItem(USER_INFO)
  if (userInfo) localStorage.removeItem(USER_INFO)
}

export const getBasicUserInfo = () => {
  const basicUserInfo = sessionStorage.getItem(BASIC_USER_INFO);
  if (basicUserInfo) {
    return JSON.parse(basicUserInfo)
  } else {
    sessionStorage.setItem(BASIC_USER_INFO, "");
  }
  return ''
}