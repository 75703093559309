

/**
 * 判断是否为空
 * undefined => true
 * null => true
 * [] => true
 * {} => true
 * "" => true
 * "    " => true
 * @param target 目标
 * @returns {boolean}
 */
export function isEmpty(target) {
  if (target === undefined) return true;
  if (target === 'undefined') return true;
  if (target === null) return true;
  if (target === 'null') return true;
  if (isArray(target) && target.length === 0) return true;
  if (isObject(target) && Object.getOwnPropertyNames(target).length === 0) return true;
  if (`${target}`.trim().length === 0) return true;
  return false;
}

export function isString(obj) {
  return Object.prototype.toString.call(obj).slice(8, -1) === 'String';
}

export function isNumber(obj) {
  return Object.prototype.toString.call(obj).slice(8, -1) === 'Number';
}

export function isBoolean(obj) {
  return Object.prototype.toString.call(obj).slice(8, -1) === 'Boolean';
}

export function isFunction(obj) {
  return Object.prototype.toString.call(obj).slice(8, -1) === 'Function';
}

export function isNull(obj) {
  return Object.prototype.toString.call(obj).slice(8, -1) === 'Null';
}

export function isUndefined(obj) {
  return Object.prototype.toString.call(obj).slice(8, -1) === 'Undefined';
}

export function isObject(obj) {
  return Object.prototype.toString.call(obj).slice(8, -1) === 'Object';
}

export function isArray(obj) {
  return Object.prototype.toString.call(obj).slice(8, -1) === 'Array';
}

export function isDate(obj) {
  return Object.prototype.toString.call(obj).slice(8, -1) === 'Date';
}

export function isRegExp(obj) {
  return Object.prototype.toString.call(obj).slice(8, -1) === 'RegExp';
}

export function isError(obj) {
  return Object.prototype.toString.call(obj).slice(8, -1) === 'Error';
}

export function isSymbol(obj) {
  return Object.prototype.toString.call(obj).slice(8, -1) === 'Symbol';
}

export function isPromise(obj) {
  return Object.prototype.toString.call(obj).slice(8, -1) === 'Promise';
}

export function isSet(obj) {
  return Object.prototype.toString.call(obj).slice(8, -1) === 'Set';
}

/**
 * 文本域（内容转html）
 * @param str 格式化字符串
 * @returns {string}
 */
export function textareaConvertHtml(str) {
  return str.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>').replace(/\s/g, ' ');
}

/**
 * 文本域（html转内容）
 * @param str 格式化字符串
 * @returns {*|void|string}
 */
export function htmlConvertTextarea(str) {
  return str.replace(/<br\/>/g, '\r\n');
}

/**
 * 截取文本
 * @param str 要截取的字符串
 * @param startNum 开始值
 * @param endNum 结束值
 * @returns {string}
 */
export function subStr(str, startNum, endNum) {
  return str.substring(startNum, endNum);
}

/**
 * 深拷贝
 * @param obj
 * @returns {any}
 */
// 深拷贝对象
export function deepClone(obj) {
  const _toString = Object.prototype.toString;
  // null, undefined, non-object, function
  if (!obj || typeof obj !== 'object') {
    return obj;
  }
  // DOM Node
  if (obj.nodeType && 'cloneNode' in obj) {
    return obj.cloneNode(true);
  }
  // Date
  if (_toString.call(obj) === '[object Date]') {
    return new Date(obj.getTime());
  }
  // RegExp
  if (_toString.call(obj) === '[object RegExp]') {
    const flags = [];
    if (obj.global) {
      flags.push('g');
    }
    if (obj.multiline) {
      flags.push('m');
    }
    if (obj.ignoreCase) {
      flags.push('i');
    }
    return new RegExp(obj.source, flags.join(''));
  }
  const result = Array.isArray(obj) ? [] : obj.constructor ? new obj.constructor() : {};
  for (const key in obj) {
    result[key] = deepClone(obj[key]);
  }
  return result;
}

/**
 * 复制
 * @param copyValue 复制内容
 * @returns {Promise<unknown>}
 */
export function copyInfo(copyValue) {
  return new Promise((resolve) => {
    const copyUrl = document.createElement('input'); // 创建一个input框获取需要复制的文本内容
    copyUrl.value = copyValue;
    const appDiv = document.getElementById('app');
    appDiv.appendChild(copyUrl);
    copyUrl.select();
    document.execCommand('copy');
    copyUrl.remove();
    window.$message.success(window.$t('common.CopySucceeded'));
    resolve(true);
  });
}

/**
 * 返回id集合字符串（id-1,id-2,id-3）
 * @param rows 集合
 * @returns {string}
 */
export function getIdsStr(rows) {
  let ids = '';
  rows.forEach(row => {
    ids += row.id + ',';
  });
  return ids.substring(0, ids.length - 1);
}

/**
 * 去除空格
 * @param str
 * @param type 1-所有空格 2-前后空格 3-前空格 4-后空格
 * @returns {*|void|string}
 */
export function trim(str, type) {
  type = type || 1;
  switch (type) {
    case 1:
      return str.replace(/\s+/g, '');
    case 2:
      return str.replace(/(^\s*)|(\s*$)/g, '');
    case 3:
      return str.replace(/(^\s*)/g, '');
    case 4:
      return str.replace(/(\s*$)/g, '');
    default:
      return str;
  }
}

/**
 * 在字符串中插入新字符串
 * @param soure 源字符串
 * @param index 插入坐标
 * @param newStr  新字符串
 * @returns {*}
 */
export function insertStr(soure, index, newStr) {
  const str = soure.slice(0, index) + newStr + soure.slice(index);
  return str;
}

/**
 * 滚动到顶部
 */
export function scrollToTop() {
  const c = document.documentElement.scrollTop || document.body.scrollTop;
  if (c > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, c - c / 8);
  }
}

/**
 * 去除html标签
 * @param str
 * @returns {*|void|string}
 */
export function removeHtmlTag(str) {
  return str.replace(/<[^>]+>/g, '');
}

/**
 * 动态引入JS
 * @param src JS位置
 */
export function injectScript(src) {
  const s = document.createElement('script');
  s.type = 'text/javascript';
  s.async = true;
  s.src = src;
  const t = document.getElementsByTagName('script')[0];
  t.parentNode.insertBefore(s, t);
}

/**
 * iphone是否有安全区
 */
export function getIsIphoneFull() {
  let events = navigator.userAgent;
  if (events.indexOf('Android') > -1 || events.indexOf('Linux') > -1 || events.indexOf('Adr') > -1) {
    return false;
  } else if (events.toLowerCase().indexOf('iphone') > -1) {
    // iPhone X/iPhone XS/iPhone 11/iPhone 12
    if (screen.height === 812 && screen.width === 375) {
      return true;
    } else if (screen.height === 896 && screen.width === 414) { // iPhone XS/iPhone XR/iPhone 11/
      return true;
    } else if (screen.height === 844 && screen.width === 390) { // iPhone 12/iPhone 12 Pro
      return true;
    } else if (screen.height === 926 && screen.width === 428) { // iPhone 12 Pro Max
      return true;
    } else {
      return false;
    }
  } else if (events.indexOf('Windows Phone') > -1) {
    return false;
  } else if (events.indexOf('iPad') > -1) {
    return false;
  }
}

/**
 * 获取地址栏参数（转小写）
 * @param name
 * @returns {string|null}
 */
export function getQueryStringLower(name) {
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  let r = decodeURI(window.location.search.substr(1)).toLowerCase().match(reg);
  if (r != null)return unescape(r[2]);
  return null;
}

/**
 * 获取url参数
 * @param name
 * @returns {*}
 */
export const getQueryString = (name) => {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  const search = window.location.search.split('?')[1] || '';
  const r = search.match(reg) || [];
  return r[2];
}


/**
 * 账号显示掩码
 * @param userinfo
 * @returns {string|*}
 */
export function getAccountMaskCode (userinfo) {
  if(!isEmpty(userinfo.M_U_Mobile)) {
    let reg = /^(\d{3})\d{4}(\d{4})$/;
    return userinfo.M_U_Mobile.replace(reg, "$1****$2");
  }
  if(!isEmpty(userinfo.M_C_Mail)) {
    return userinfo.M_C_Mail;
  }
  return '';
}

/**
 * 清除两端符号
 * @param str 目标字符串
 * @param symbol 符号类型: 1.comma-逗号 | 2.stop-顿号
 * @returns {boolean}
 */
export function clearBothSymbol(str,symbol) {
  let rep = '';
  switch (symbol) {
    case 'comma':   //逗号
      rep = /，$/gi;
      break;
    case 'stop':   //顿号
      rep = /、$/gi;
      break;
    default:
      return true
  }
  return str.replace(rep, "");
}

// 中国标准时间 转换成 年月日
export function formatDateTime (date) {
  let y = date.getFullYear();
  let m = date.getMonth() + 1;
  m = m < 10 ? ('0' + m) : m;
  let d = date.getDate();
  d = d < 10 ? ('0' + d) : d;
  let h = date.getHours();
  let minute = date.getMinutes();
  minute = minute < 10 ? ('0' + minute) : minute;
  // return y + '-' + m + '-' + d+' '+h+':'+minute;
  return y + '-' + m + '-' + d + ' ' + h + ':' + minute;
}

// 中国标准时间 转换成 年月
export function formatYearMonthDate (date) {
  let y = date.getFullYear();
  let m = date.getMonth() + 1;
  m = m < 10 ? ('0' + m) : m;
  return y + '-' + m
}

// 中国标准时间 转换成 年月日
export function formatYearMonthDayDate (date) {
  let y = date.getFullYear();
  let m = date.getMonth() + 1;
  m = m < 10 ? ('0' + m) : m;
  let d = date.getDate();
  d = d < 10 ? ('0' + d) : d;
  return y + '-' + m + '-' + d;
}

/**
 * 格式化富文本内容
 * @param str
 * @returns {*}
 */
export function getFormatRichText(str) {
  str=str.replace('wx_fmt=gif','');
  str=str.replace('wx_fmt=jpeg','');
  str=str.replace('wx_fmt=png','');
  let reg = new RegExp("<video","g");//g,表示全部替换
  str=str.replace(reg,"<video style='max-width:100% !important;'");
  let regImg = new RegExp("<img","g");//g,表示全部替换
  str=str.replace(regImg,"<img style='max-width:100% !important;'")
  let regTable = new RegExp("<table","g");//g,表示全部替换
  str=str.replace(regTable,"<table style='width:100% !important;'")
  return str;
}

/**
 * 密码验证（只包含数字和字母）
 * @param str
 * @returns {boolean}
 */
export function pwdLetterNumberVerify(str) {
  let pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？_]");
  let letterDigitReg = new RegExp(/[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/);
  if(pattern.test(str)) {
    return false;
  } else if (!letterDigitReg.test(str)) {
    return false;
  }
  return true;
}

export function goToTop() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  window.pageYOffset = 0;
}

/**
 * @description: 裁剪图片压缩
 */
export function compressImg(file) {
  let disposeFile = file;
  const read = new FileReader();
  const fileSize = parseFloat(parseInt(disposeFile.size) / 1024 / 1024).toFixed(2);
  return new Promise((resolve, reject) => {
    try {
      read.readAsDataURL(disposeFile);
      read.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = function () {
          let scale = 1;
          if(fileSize >= 0.2 && fileSize < 0.6) {
            scale = 1.5;
          } else if(fileSize >= 0.6) {
            scale = 2;
          }
          let w = Math.floor(this.width / scale),
              h = Math.floor(this.height / scale);
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          let base64 = canvas.toDataURL(disposeFile.type, 1);
          canvas.setAttribute('width', w);
          canvas.setAttribute('height', h);
          ctx.drawImage(this, 0, 0, w, h);
          console.log(fileSize)
          // 小于200KB不压缩
          if (fileSize >= 0.2 && fileSize < 0.6) {
            base64 = canvas.toDataURL(disposeFile.type, 0.8);
          } else if(fileSize >= 0.6) {
            base64 = canvas.toDataURL(disposeFile.type, 0.5);
          } else {
            base64 = canvas.toDataURL(disposeFile.type, 1);
          }
          let objFile = dataURLtoFile(base64, disposeFile.name);
          resolve({file: objFile, base64Url: base64});
        };
      };
    } catch (error) {
      console.log(error)
      reject(disposeFile);
    }
  });
}

/**
 * @description: 图片压缩
 */
export function uploaderCompressImg(file) {
  let disposeFile = file;
  console.log(disposeFile.size)
  const read = new FileReader();
  const fileSize = parseFloat(parseInt(disposeFile.size) / 1024 / 1024).toFixed(2);
  return new Promise((resolve, reject) => {
    try {
      read.readAsDataURL(disposeFile);
      read.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = function () {
          let scale = 1;
          if(fileSize <= 1) {
            scale = 1.5;
          } else if(fileSize > 1 && fileSize <= 3) {
            scale = 2;
          } else if(fileSize > 3 && fileSize <= 5) {
            scale = 2.5;
          }
          let w = Math.floor(this.width / scale),
              h = Math.floor(this.height / scale);
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          let base64 = canvas.toDataURL(disposeFile.type, 1);
          canvas.setAttribute('width', w);
          canvas.setAttribute('height', h);
          ctx.drawImage(this, 0, 0, w, h);
          console.log(fileSize)
          // 小于1MB不压缩
          if (fileSize <= 1) {
            base64 = canvas.toDataURL(disposeFile.type, 0.8);
          } else if(fileSize > 1 && fileSize <= 3) {
            base64 = canvas.toDataURL(disposeFile.type, 0.5);
          } else if(fileSize > 3 && fileSize <= 5) {
            base64 = canvas.toDataURL(disposeFile.type, 0.45);
          } else {
            base64 = canvas.toDataURL(disposeFile.type, 1);
          }
          let objFile = dataURLtoFile(base64, disposeFile.name);
          resolve({file: objFile, base64Url: base64});
        };
      };
    } catch (error) {
      console.log(error)
      reject(disposeFile);
    }
  });
}

/**
 * @description: 将base64编码转回file文件
 */
export function dataURLtoFile(dataUrl, fileName) {
  let arr = dataUrl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bStr = atob(arr[1]),
      n = bStr.length,
      u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bStr.charCodeAt(n);
  }
  return new File([u8arr], fileName, {
    type: mime,
  });
}

/**
 * 将file文件上传转化为base64进行显示
 * @param file
 * @returns {Promise<unknown>}
 */
export function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.onload = (e) => {
      let data
      if (typeof e.target.result === 'object') {
        data = window.URL.createObjectURL(new Blob([e.target.result]))
      } else {
        data = e.target.result
      }
      resolve(data);
    }
    //转化为base64
    reader.readAsDataURL(file);
    reader.onerror = error => reject(error)
  })
}

export function fileToBlob(file) {
  return new Promise((resolve, reject) => {
    //转化为blob
    let reader = new FileReader();
    reader.readAsArrayBuffer(file);
    let blob = null;
    reader.onload = (e) => {
      if (typeof e.target.result === 'object') {
        blob = new Blob([e.target.result])
      } else {
        blob = e.target.result
      }
      resolve(blob);
    }
    // 失败返回失败的信息
    reader.onerror = error => reject(error)
  })
}

/**
 * 获取是否PC
 * @returns {boolean}
 */
export const isPC = () => {
  let userAgentInfo = navigator.userAgent;
  let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
  let flag = true;
  for (let i = 0; i < Agents.length; i++) {
    if (userAgentInfo.indexOf(Agents[i]) !== -1) {
      flag = false;
      break;
    }
  }
  if(userAgentInfo.toLowerCase().indexOf("micromessenger")>=0) {
    flag = false;
  }
  return flag;
}

/**
 * 动态加载js文件
 */
export function loadJS(src, isCache = false) {
  return new Promise((resolve, reject) => {
    let script = document.createElement('script');
    let head = document.head;
    script.type = 'text/JavaScript';
    if (isCache) {
      script.src = src + "?t=" + new Date().getTime();
    } else {
      script.src = src
    }
    if (script.addEventListener) {
      script.addEventListener('load', resolve, false);
      script.addEventListener('error', reject, false);
    }
    head.appendChild(script);
  });
}
/**
 * 检查字符串类型
 * @param str 字符串
 * @param type 验证类型
 * @returns {boolean}
 */
export function checkStr (str, type) {
  switch (type) {
    case 'phone':   //手机号码
      return /^1[3|4|5|6|7|8|9][0-9]{9}$/.test(str);
    case 'tel':     //座机
      return /^(0\d{2,3}-\d{7,8})(-\d{1,4})?$/.test(str);
    case 'card':    //身份证
      return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(str);
    case 'pwd':     //密码以字母开头，长度在6~18之间，只能包含字母、数字和下划线
      // return /^[a-zA-Z]\w{5,17}$/.test(str)
      return /^[a-zA-Z0-9~!@#\$%\^\&*\)\(+=._-]{6,18}$/.test(str)
    case 'password': //8位以上,并包含大小写,长度要在8-20位之间
      return  /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,20}$/.test(str)
    case 'postal':  //邮政编码
      return /[1-9]\d{5}(?!\d)/.test(str);
    case 'QQ':      //QQ号
      return /^[1-9][0-9]{4,9}$/.test(str);
    case 'email':   //邮箱
      return /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(str);
    case 'money':   //金额(小数点2位)
      return /^\d*(?:\.\d{0,2})?$/.test(str);
    case 'URL':     //网址
      return /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/.test(str)
    case 'IP':      //IP
      return /((?:(?:25[0-5]|2[0-4]\\d|[01]?\\d?\\d)\\.){3}(?:25[0-5]|2[0-4]\\d|[01]?\\d?\\d))/.test(str);
    case 'date':    //日期时间
      return /^(\d{4})\-(\d{2})\-(\d{2}) (\d{2})(?:\:\d{2}|:(\d{2}):(\d{2}))$/.test(str) || /^(\d{4})\-(\d{2})\-(\d{2})$/.test(str)
    case 'number':  //数字
      return /^[0-9]$/.test(str);
    case 'english': //英文
      return /^[a-zA-Z]+$/.test(str);
    case 'chinese': //中文
      return /^[\\u4E00-\\u9FA5]+$/.test(str);
    case 'lower':   //小写
      return /^[a-z]+$/.test(str);
    case 'upper':   //大写
      return /^[A-Z]+$/.test(str);
    case 'HTML':    //HTML标记
      return /<("[^"]*"|'[^']*'|[^'">])*>/.test(str);
    case 'idNumber': // 二代身份证号
      return  /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/.test(str)
    default:
      return true;
  }
}
export const getLogoutPath=(userType)=>{
  let path = '/schoolLogin'
  // if (userType === 2) {
  //   path = '/studentLogin'
  // }
  // if (userType === 3) {
  //   path = '/schoolLogin'
  // }
  //
  return  path
}