import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import  student from  './modules/student'
import  school from  './modules/school'

const routes = [
	{
		path: '/',
		name: 'home',
		component: HomeView,
		meta: { title: '首页',menuIndex:0 },
	},
	{
		path: '/hotNews',
		name: 'hotNews',
		component: () =>
			import(/* webpackChunkName: "hotNews" */ '../views/HotNews.vue'),
		meta: { title: '最新消息',menuIndex:1},
	},
	{
		path: '/newsDetails',
		name: 'newsDetails',
		component: () =>
			import(/* webpackChunkName: "newsDetails" */ '../views/NewsDetails.vue'),
		meta: { title: '详情' ,menuIndex:1},
	},
	{
		path: '/register',
		name: 'register',
		component: () =>
			import(/* webpackChunkName: "register" */ '../views/RegisterView.vue'),
		meta: { title: '账号注册',menuIndex:5 },
	},
	{
		path: '/login',
		name: 'login',
		component: () =>
			import(/* webpackChunkName: "login" */ '../views/LoginView.vue'),
		meta: { title: '登录',menuIndex:5  },
	},
	{
		path: '/verify',
		name: 'verify',
		component: () =>
			import(/* webpackChunkName: "verify" */ '../views/VerifyView.vue'),
		meta: { title: '证书验证',menuIndex:5  },
	},
	{
		path: '/resetPasswordVerify',
		name: 'resetPasswordVerify',
		component: () =>
			import(/* webpackChunkName: "resetPasswordVerify" */ '../views/ResetPasswordVerify.vue'),
		meta: { title: '找回密码',menuIndex:-1  },
	},
	{
		path: '/resetPassword',
		name: 'resetPassword',
		component: () =>
			import(/* webpackChunkName: "resetPassword" */ '../views/ResetPassword.vue'),
		meta: { title: '修改密码',menuIndex:-1  },
	},
	{
		path: '/contactus',
		name: 'contactus',
		component: () =>
			import(/* webpackChunkName: "contactus" */ '../views/ContactUs.vue'),
		meta: { title: '联系我们',menuIndex:-1  },
	},
	{
		path: '/about',
		name: 'about',
		component: () =>
			import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
		meta: { title: '关于我们',menuIndex:-1  },
	},
	{
		path: '/introductionToProExam',
		name: 'introductionToProExam',
		component: () => import(/* webpackChunkName: "IntroductionToProExam" */ '../views/IntroductionToProExam.vue'),
		meta: { title: 'AHK专业考试介绍',menuIndex:-1  },
	},
	{
		path: '/introductionToTraining',
		name: 'introductionToTraining',
		component: () => import(/* webpackChunkName: "IntroductionToProExam" */ '../views/IntroductionToTrainingView.vue'),
		meta: { title: 'AEVO培训介绍',menuIndex:-1  },
	},
	{
		path: '/privacyPolicy',
		name: 'privacyPolicy',
		component: () => import(/* webpackChunkName: "privacyPolicy" */ '../views/agreement/PrivacyPolicy.vue'),
		meta: { title: '隐私条款',menuIndex:-1  },
	},
	{
		path: '/disclaimer',
		name: 'disclaimer',
		component: () => import(/* webpackChunkName: "disclaimer" */ '../views/agreement/Disclaimer.vue'),
		meta: { title: '隐私条款',menuIndex:-1  },
	},
	{
		path: '/standardTerms',
		name: 'standardTerms',
		component: () => import(/* webpackChunkName: "standardTerms" */ '../views/agreement/StandardTerms.vue'),
		meta: { title: '隐私条款',menuIndex:-1  },
	},
	{
		path: '/verifyCertificate',
		name: 'verifyCertificate',
		component: () => import(/* webpackChunkName: "verifyCertificate" */ '../views/VerifyCertificate.vue'),
		meta: { title: '证书验证',menuIndex:-1  },
	},
	...student,
	...school

]
// NewsDetails
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
	scrollBehavior() {
		return {
			top: 0,
            behavior: 'smooth',
        };
	},
})

export default router
