import store from '@/store'
export const PERMS='PERMS'

export default function checkPermission(value) {
    const perms = store.state.school.perms
    // '0'是主账号,有所有的权限
    if(perms==='0')return true
    if (value) {
        return perms?.includes(value)
    } else {
        return false
    }
}