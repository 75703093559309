const student = [{
    path: '/studentLogin',
    name: 'studentLogin',
    component: () =>
        import(/* webpackChunkName: "studentLogin" */ '@/views/student/StudentLogin.vue'),
    meta: {title: '考生登录',menuIndex:4 },
},
    {
        path: '/studentHome',
        name: 'studentHome',
        component: () =>
            import(/* webpackChunkName: "studentHome" */ '@/views/student/StudentHome.vue'),
        meta: {title: '考生服务',menuIndex:4},
    },
    {
        path: '/personalInfo',
        name: 'personalInfo',
        component: () =>
            import(/* webpackChunkName: "personalInfo" */ '@/views/student/PersonalInfo.vue'),
        meta: {title: '个人信息',menuIndex:4},
    },
    {
        path: '/myCertificate',
        name: 'myCertificate',
        component: () =>
            import(/* webpackChunkName: "myCertificate" */ '@/views/student/MyCertificate.vue'),
        meta: {title: '我的证书',menuIndex:4},
    },
    {
        path: '/examList',
        name: 'examList',
        component: () =>
            import(/* webpackChunkName: "examList" */ '@/views/student/ExamList.vue'),
        meta: {title: '我的考试',menuIndex:4},
    },
    {
        path: '/learningLog',
        name: 'learningLog',
        component: () =>
            import(/* webpackChunkName: "learningLog" */ '@/views/student/LearningLog.vue'),
        meta: {title: '学习日志',menuIndex:4},
    },
    {
        path: '/studentExamDetails',
        name: 'studentExamDetails',
        component: () =>
            import(/* webpackChunkName: "studentExamDetails" */ '@/views/student/StudentExamDetails.vue'),
        meta: {title: '学习日志',menuIndex:4},
    },
]

export default student